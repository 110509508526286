<template>

  <div>

    <DriverRegister v-if="this.$route.params.userType==='Driver'" v-bind:role="this.$route.params.userType" />
    <PassengerRegister v-if="this.$route.params.userType==='User'" v-bind:role="this.$route.params.userType" />

  </div>

</template>


<script>
  import DriverRegister from "../Auth/DriverRegister.vue";
  import PassengerRegister from "../Auth/PassengerRegister.vue";
  export default {
    name: "Register",

    data: function () {
      return {

      };
    },
    components: {
      DriverRegister,
      PassengerRegister
    },
    computed: {},

    mounted() {},


    methods: {

    }
  };
</script>