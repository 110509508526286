<template>
  <div>
    <div class="sign-up screen">
      <div class="row w-100 mx-0" style="height:100vh">
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-6 left-bg">
          <div class="right-bg">
            <div class="align-items-center mobilepage ">
              <div class="auth-form-light text-left p-5 px-sm-5">
                <div class="text-center pb-3">
                  <h1 class="title roboto-black-black-50px pb-3">
                    <span class="roboto-black-black-50px">Sign up</span>
                  </h1>
                  <div class="text-4 roboto-light-black-17px">
                    <span class="roboto-light-black-17px"
                      >Welcome to the new, simple and reliable world.</span
                    >
                  </div>
                </div>

                <form class="pt-3" ref="scrollToRegisterForm">
                  <div class="form-group">
                    <strong class="roboto-normal-stack-17px p-2"
                      >Your first name</strong
                    >

                    <MazInput
                      v-model="details.first_name"
                      :error="errors.first_name ? true : false"
                      :success="details.first_name ? true : false"
                      placeholder="Enter first name"
                      autocomplete="new-first-name"
                      :loading="isLoading ? true : false"
                      @input="formValidation()"
                      clearable
                    />

                    <code v-if="errors.first_name">
                      {{ errors.first_name[0] }}
                    </code>
                  </div>

                  <div class="form-group">
                    <strong class="roboto-normal-stack-17px p-2"
                      >Your last name</strong
                    >

                    <MazInput
                      v-model="details.last_name"
                      :error="errors.last_name ? true : false"
                      :success="details.last_name ? true : false"
                      placeholder="Enter last name"
                      autocomplete="new-last-name"
                      :loading="isLoading ? true : false"
                      clearable
                      @input="formValidation()"
                    />

                    <code v-if="errors.first_name">
                      {{ errors.first_name[0] }}
                    </code>
                  </div>

                  <div class="form-group">
                    <strong class="roboto-normal-stack-17px p-2"
                      >Your business name (optional)</strong
                    >

                    <MazInput
                      v-model="details.business_name"
                      :success="details.business_name ? true : false"
                      placeholder="Enter business name (optional)"
                      autocomplete="new-business-name"
                      :loading="isLoading ? true : false"
                      clearable
                    />

                    <code v-if="errors.first_name">
                      {{ errors.first_name[0] }}
                    </code>
                  </div>

                  <div class="form-group mt-3">
                    <strong class="roboto-normal-stack-17px pb-2"
                      >Your email</strong
                    >

                    <MazInput
                      v-model="details.email"
                      :error="errors.email ? true : false"
                      :success="details.email && !errors.email ? true : false"
                      placeholder="Enter email"
                      :loading="isLoading ? true : false"
                      type="email"
                      autocomplete="new-email"
                      @input="formValidation()"
                      clearable
                    />
                    <!-- <span v-if="emailVerifyLoading">Loading ....</span> -->

                    <code v-if="errors.email">
                      {{ errors.email[0] }}
                    </code>
                    <code v-if="isEmailExistOnDb">
                      This email address is already being used. Please choose a
                      different email address!
                    </code>
                  </div>

                  <div class="form-group mt-3">
                    <strong class="roboto-normal-stack-17px pb-2"
                      >Your phone</strong
                    >
                    <MazPhoneNumberInput
                      v-model="phone"
                      :error="errors.phone ? true : false"
                      :success="phone ? true : false"
                      :loading="isLoading ? true : false"
                      show-code-on-list
                      default-country-code="RW"
                      :default-phone-number="phone"
                      @update="phoneResults = $event"
                      @input="formValidation()"
                      clearable
                    />
                  </div>

                  <div class="form-group mt-3">
                    <strong class="roboto-normal-stack-17px pb-2"
                      >Your password</strong
                    >

                    <MazInput
                      v-model="details.password"
                      :error="errors.password ? true : false"
                      :success="details.password ? true : false"
                      :loading="isLoading ? true : false"
                      type="password"
                      placeholder="Enter password"
                      autocomplete="new-password"
                      @input="formValidation()"
                      left-icon-name="lock"
                      clearable
                    />

                    <code v-if="errors.password">
                      {{ errors.password[0] }}
                    </code>
                  </div>

                  <div class="form-group mt-3">
                    <strong class="roboto-normal-stack-17px pb-2"
                      >Confirm Password</strong
                    >

                    <MazInput
                      v-model="details.password_confirmation"
                      :error="errors.password_confirmation ? true : false"
                      :success="
                        details.password_confirmation &&
                        !errors.password_confirmation
                          ? true
                          : false
                      "
                      :loading="isLoading ? true : false"
                      type="password"
                      placeholder="Confirm password"
                      autocomplete="new-password-confirmation"
                      @input="formValidation()"
                      left-icon-name="lock"
                      clearable
                    />

                    <code v-if="errors.password_confirmation">
                      {{ errors.password_confirmation[0] }}
                    </code>
                  </div>

                  <div
                    class="my-2 d-flex justify-content-between align-items-center"
                  >
                    <div class="form-check form-check-flat form-check-primary">
                      <label
                        class="form-check-label"
                        v-if="errors.agree && !details.agree"
                      >
                        <MazCheckbox
                          v-model="details.agree"
                          color="danger"
                          @input="formValidation()"
                        >
                          <code>
                            <span
                              class="roboto-medium-black-15px"
                              style="color:#FF4747;"
                              >I agree to the </span
                            ><span class="span1" style="color:#FF4747;"
                              >Terms &amp; Conditions</span
                            >
                          </code>
                        </MazCheckbox>
                      </label>

                      <label
                        class="form-check-label"
                        v-if="!errors.agree || details.agree"
                      >
                        <MazCheckbox
                          v-model="details.agree"
                          @input="formValidation()"
                        >
                          <span class="roboto-medium-black-15px"
                            >Please, agree to the </span
                          ><span class="span1">Terms &amp; Conditions</span>
                        </MazCheckbox>
                      </label>
                    </div>
                  </div>

                  <div
                    class="form-group mt-6"
                    v-if="
                      errors.message ||
                        (errors.error && errors.error !== 'token_invalid')
                    "
                  >
                    <MessageErrorFadeOut
                      v-bind:message="errors.message"
                      v-bind:show="errors && errors.message ? true : false"
                    />
                  </div>

                  <div class="mt-3">
                    <button
                      type="button"
                      @click="register"
                      :disabled="isLoading ? '' : isLoading"
                      class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    >
                      <span v-if="!isLoading"> Sign Up</span>
                      <span v-if="isLoading">
                        <i class="fas fa-circle-notch fa-spin"></i> Signing
                        up...</span
                      >
                    </button>
                  </div>

                  <div class="text-center mt-4 font-weight-light">
                    <p class="text-center text-1 roboto-bold-bay-of-many-15px">
                      <span class="span0">Already have an account?</span
                      ><span class="roboto-bold-bay-of-many-15px">&nbsp; </span>
                      <router-link
                        class="span2"
                        :to="{
                          name: 'Login',
                          params: { userType: this.$route.params.userType },
                        }"
                        >SIGN IN</router-link
                      >
                    </p>
                  </div>
                </form>
              </div>
              <div class="align-items-center mb-5">
                <FooterBar />
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-lg-6 col-sm-6 col-md-6 col-xs-6 d-none d-lg-block"
          style="width:100%"
        >
          <div class="group-93">
            <div class="overlap-group4">
              <div class="row" style="height:50%">
                <div class="col-12">
                  <div class="group-74">
                    <img
                      class="iposita-ondeman-ransparent-bg-1"
                      src="/images/driver/iposita-ondemand1-transparent-bg-1-1.png"
                    />
                  </div>
                </div>
              </div>
              <div class="row" style="height:50%">
                <div class="col-12 mt-3">
                  <div class="text-center text-7 roboto-light-white-31px p-4">
                    <span class="text-center roboto-light-white-31px"
                      >The Logistics </span
                    ><span class="span1-1">Platform</span
                    ><span class="roboto-light-white-31px"> built for you</span>
                  </div>

                  <img class="bikes-1" src="/images/bikes-1-1.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <MazDialog v-model="isVerifyPhoneDialogOpen" noFooter :persistent="true">
      <div slot="title">Verify Phone number</div>

      <div class="row mt-2">
        <div class="col-lg-12">
          <ValidationObserver v-slot="{ passes }">
            <form @submit.prevent="passes(sendRegisterReq)" class="form">
              <strong class="roboto-normal-stack-17px pb-2">Enter OTP</strong>
              <ValidationProvider
                name="otp"
                rules="required"
                v-slot="{ errors }"
              >
                <MazInput
                  placeholder="Enter OTP"
                  v-model="details.otp"
                  :name="`otp`"
                  autocomplete="otp"
                  clearable
                />
                <div
                  class="row"
                  style="    margin-top: 10px;margin-bottom: 10px;"
                >
                  <div class="col-lg-6">
                    <code v-if="errors.length > 0">OTP is required</code>
                  </div>
                  <div class="col-lg-6">
                    <button
                      type="button"
                      @click="sendOTPSms()"
                      class="btn btn-warning btn-sm"
                      style="border-radius: 10px;float: right;"
                    >
                      Resend again!
                    </button>
                  </div>
                </div>
              </ValidationProvider>

              <button
                type="submit"
                style="background: rgba(31, 66, 140, 1);border-radius: 10px;"
                class="btn btn-primary btn-md btn-block font-weight-medium auth-form-btn"
              >
                Verify OTP
              </button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </MazDialog>
    <MazDialog v-model="isLoading" noFooter noClose :persistent="true">
      <div slot="title" v-if="isLoading">Loading...</div>
      <div class="row w-100 mx-auto">
        <div class="col-lg-3"></div>

        <div class="col-lg-6">
          <MazLoader />
        </div>

        <div class="col-lg-3"></div>
      </div>
    </MazDialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MessageErrorFadeOut from "../Common/Message/MessageErrorFadeOut";
import FooterBar from "../Layouts/FooterBar";
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  name: "PassengerRegister",
  props: ["role"],
  data: function() {
    return {
      isLoading: false,
      formErrors: {},
      phoneResults: null,
      business_name: null,
      emailVerifyLoading: false,
      isEmailExistOnDb: false,
      isVerifyPhoneDialogOpen: false,
      phone: null,
      hasError: false,
      currentOTP: null,
      details: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        password_confirmation: null,
        device_type: "android",
        device_token: "no device",
        device_id: this.getDeviceId(),
        service_type: 1,
        country_code: null,
        login_by: null,
        mobile: null,
        otp: null,
        photos: [],
        id: [],
        agree: false,
      },
    };
  },
  components: {
    MessageErrorFadeOut,
    FooterBar,
    ValidationObserver,
    ValidationProvider,
  },
  computed: {
    ...mapGetters(["errors", "isSuccess"]),
  },

  mounted() {
    Event.$on("isEmailExist", (response) => {
      this.emailVerifyLoading = false;

      if (Boolean(response) == true) {
        this.isEmailExistOnDb = true;
      } else {
        this.isEmailExistOnDb = false;
      }
    });

    Event.$on("otpSent", (response) => {
      this.isVerifyPhoneDialogOpen = true;
      this.isLoading = false;
      this.currentOTP = response.otp;
    });

    this.$store.commit("setErrors", {});
    this.$store.commit("setIsSuccess", false);
    this.scrollTologin();
  },

  methods: {
    ...mapActions("auth", [
      "sendRegisterRequest",
      "sendVerifyEmailRequest",
      "sendOTPRequest",
    ]),
    scrollTologin() {
      const el = this.$refs.scrollToRegisterForm;

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    getDeviceId() {
      var navigator_info = window.navigator;
      var screen_info = window.screen;
      var uid = navigator_info.mimeTypes.length;
      uid += navigator_info.userAgent.replace(/\D+/g, "");
      uid += navigator_info.plugins.length;
      uid += screen_info.height || "";
      uid += screen_info.width || "";
      uid += screen_info.pixelDepth || "";
      return uid;
    },
    validEmail: function(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    formValidation() {
      this.hasError = false;
      this.formErrors = {};

      if (this.details.email) {
        this.verifyExistingEmail();
      }

      if (!this.details.first_name) {
        this.formErrors.first_name = ["First name required."];
        this.hasError = true;
      }

      if (!this.details.last_name) {
        this.formErrors.last_name = ["Last name required."];
        this.hasError = true;
      }
      if (!this.details.password) {
        this.formErrors.password = ["Password required."];
        this.hasError = true;
      }

      if (!this.phone) {
        this.formErrors.phone = ["Phone required."];
        this.hasError = true;
      }

      if (!this.details.email) {
        this.formErrors.email = ["Email required."];
        this.hasError = true;
      }

      if (!this.validEmail(this.details.email)) {
        this.formErrors.email = ["Valid email required."];
        this.hasError = true;
      }
      //agree

      if (!this.details.agree) {
        this.formErrors.agree = [false];
        this.hasError = true;
      }

      if (!this.details.password_confirmation) {
        this.formErrors.password_confirmation = [
          "Password confirmation required.",
        ];
        this.hasError = true;
      }

      if (this.details.password_confirmation != this.details.password) {
        this.formErrors.password_confirmation = ["Password does not match"];
        this.hasError = true;
      }
      if (this.hasError) {
        this.$store.commit("setErrors", this.formErrors);

        return false;
      }
      return true;
    },
    sendRegisterReq() {
      if (this.currentOTP == this.details.otp) {
        this.isVerifyPhoneDialogOpen = false;
        this.isLoading = true;
        this.submitUserInfo();
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Invalid OTP",
          text: "Please enter a valid otp!",
          duration: 10000,
          speed: 1000,
        });
      }
    },
    verifyExistingEmail: function() {
      this.emailVerifyLoading = true;
      clearTimeout(this.timeout);
      const parms = { email: this.details.email };
      this.timeout = setTimeout(() => {
        this.sendVerifyEmailRequest(parms).then();
      }, 2000);
    },
    sendOTPSms: function() {
      const parms = {
        mobile: this.details.mobile,
        country_code: this.details.country_code,
      };
      this.sendOTPRequest(parms).then();
    },
    submitUserInfo: function() {
      this.sendRegisterRequest({ data: this.details, role: this.role }).then(
        () => {
          this.isLoading = false;
          if (this.isSuccess) {
            this.formErrors = {};
            this.$notify({
              group: "app",
              type: "success",
              title: "Done",
              text: "You have been registered successfully",
              duration: 10000,
              speed: 1000,
            });
            localStorage.setItem("authEmail", this.details.email);
            localStorage.setItem("authPassword", this.details.password);
            this.$router
              .push({
                name: "Authenticate",
                params: {
                  userType: this.$route.params.userType,
                },
              })
              .catch(() => {});
          }
        }
      );
    },
    register: function() {
      if (!this.formValidation()) {
        return false;
      }

      this.details.country_code = "+" + this.phoneResults.countryCallingCode;
      this.details.mobile = this.phoneResults.nationalNumber;
      this.details.login_by = "manual";

      if (this.isEmailExistOnDb) {
        return false;
      }
      this.isLoading = true;

      return this.sendOTPSms();
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Roboto:400,700,900,300,500");

.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.invalid-feedback {
  color: var(--sun) !important;
}

:root {
  --black: rgba(0, 0, 0, 1);
  --bay-of-many: rgba(31, 66, 140, 1);
  --stack: rgba(138, 138, 138, 1);
  --sun: rgba(249, 178, 24, 1);
  --white: rgba(255, 255, 255, 1);

  --font-size-m: 10px;
  --font-size-l: 12px;
  --font-size-xl: 15px;
  --font-size-xxl: 17px;
  --font-size-xxxl: 31px;
  --font-size-xxxxl: 50px;

  --font-family-roboto: "Roboto";
}

.roboto-light-white-31px {
  color: var(--white);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxxl);
  font-weight: 300;
  font-style: normal;
}

.roboto-black-black-50px {
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxxxl);
  font-weight: 900;
  font-style: normal;
}

.roboto-normal-stack-17px {
  color: var(--stack);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxl);
  font-weight: 400;
  font-style: normal;
}

.roboto-medium-black-15px {
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-weight: 500;
  font-style: normal;
}

.roboto-bold-bay-of-many-151px {
  color: var(--bay-of-many);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
}

.roboto-normal-black-10px {
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}

.roboto-light-black-17px {
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxl);
  font-weight: 300;
  font-style: normal;
}

.roboto-normal-stack-12px {
  color: var(--stack);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}

.roboto-bold-sun-15px {
  color: var(--sun);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
}

.sign-up {
  background-color: var(--white);
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  width: 100%;
}

.sign-up .mask-group {
  height: -417px;
  left: 0;
  position: absolute;
  top: 478px;
  width: -720px;
}

.sign-up .text-1 {
  letter-spacing: 0;
}

.sign-up .span0 {
  color: var(--black);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl2);
  font-weight: 400;
}

.sign-up .span2 {
  color: var(--bay-of-many);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl2);
  font-weight: 900;
}

.sign-up .group-75 {
  height: 73px;
  left: 119px;
  position: absolute;
  top: 230px;
  width: 485px;
}

.sign-up .place {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
}

.sign-up .form-control {
  background-image: url("/images/rectangle-30-1.png");
  background-size: 100% 100%;
  border: none !important;
  outline: none !important;
}

.sign-up .your-name {
  left: 45px;
  letter-spacing: 0;
  position: absolute;
  top: 11px;
}

.sign-up .group-76 {
  height: 73px;
  left: 119px;
  position: absolute;
  top: 316px;
  width: 485px;
}

.sign-up .email {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
}

.sign-up .text-2 {
  left: 45px;
  letter-spacing: 0;
  position: absolute;
  top: 12px;
}

.sign-up .group-79 {
  height: 73px;
  left: 119px;
  position: absolute;
  top: 402px;
  width: 487px;
}

.sign-up .password {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
}

.sign-up .min-8-characters {
  left: 45px;
  letter-spacing: 0;
  position: absolute;
  top: 12px;
}

.sign-up .show-password {
  left: 366px;
  letter-spacing: 0;
  position: absolute;
  top: 15px;
}

.sign-up .overlap-group5 {
  height: 73px;
  left: 119px;
  position: absolute;
  top: 488px;
  width: 485px;
}

.sign-up .group-80 {
  height: 73px;
  left: 0;
  position: absolute;
  top: 0;
  width: 485px;
}

.sign-up .text-3 {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
}

.sign-up .overlap-group3 {
  background-color: #c4c4c4;
  border-radius: 10px;
  height: 45px;
  left: 0;
  position: absolute;
  top: 28px;
  width: 481px;
}

.sign-up .driver {
  left: 45px;
  letter-spacing: 0;
  position: absolute;
  top: 12px;
}

.sign-up .vector {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.sign-up .overlap-group6 {
  height: 115px;
  left: 189px;
  position: absolute;
  top: 106px;
  width: 343px;
}

.sign-up .text-4 {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
}

.sign-up .title {
  letter-spacing: 0;
  text-align: center;
  white-space: nowrap;
}

.sign-up .group-9 {
  height: 45px;
  left: 119px;
  position: absolute;
  top: 648px;
  width: 481px;
}

.sign-up .group-77 {
  height: 20px;
  left: 120px;
  position: absolute;
  top: 579px;
  width: 255px;
}

.sign-up .text-5 {
  letter-spacing: 0;
}

.sign-up .span1 {
  color: var(--sun);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xl2);
  font-weight: 500;
}
.sign-up:before {
  position: fixed !important;
  background: rgba(249, 178, 24, 1);
}

.sign-up:before {
  content: "";
  position: absolute;

  right: 0px;
  bottom: 0px;
  top: 0px;
  background: rgba(249, 178, 24, 1);
  width: 50%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .btn-primary {
    color: #fff;
    background-color: var(--sun) !important;
    border-color: var(--sun) !important;
    line-height: 1 !important;
    font-weight: 400 !important;
    border-radius: 15px !important;
  }
  .span0 {
    color: rgba(0, 0, 0, 1) !important;
  }

  .span2 {
    color: var(--sun) !important;
  }

  .sign-up:before {
    position: fixed !important;
    background: #ffff !important;
  }

  .sign-up:before {
    content: "";
    position: absolute;

    right: 0px;
    bottom: 0px;
    top: 0px;
    background: #ffff !important;
    width: 100%;
    height: 100%;
  }

  .sign-up .right-bg {
    background-image: url("/images/driver/mask-group-2.png") !important;
    background-position: right top !important;
    background-repeat: no-repeat !important;
    height: 100%;
  }
  .sign-up .left-bg {
    background-image: url("/images/driver/mask-group-11.png");
    background-position: left bottom !important;
    /*Positioning*/
    background-repeat: no-repeat;
  }
  .mobilepage {
    position: relative;
    top: 45px;
  }
  .roboto-light-white-31px {
    color: var(--white);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-xxl);
    font-weight: 300;
    font-style: normal;
  }

  .login .span1 {
    color: var(--sun);
    font-family: var(--font-family-roboto);
    font-size: var(--font-size-xxl);
    font-weight: 700;
  }
  .p-5 {
    padding: 0px !important;
  }
}

.sign-up .vector-1 {
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.sign-up .overlap-group7 {
  height: 40px;
  left: 194px;
  position: absolute;
  top: 822px;
  width: 332px;
}

.sign-up .group-81 {
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 332px;
}

.sign-up .group {
  height: 8px;
  left: 0;
  position: absolute;
  top: 11px;
  width: 8px;
}

.sign-up .address {
  letter-spacing: 0;
  white-space: nowrap;
}

.sign-up .text-6 {
  left: 90px;
  letter-spacing: 0;
  position: absolute;
  top: 28px;
}

.sign-up .group-93 {
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0px;
  top: 0px;
}

.sign-up .overlap-group4 {
  height: 100%;
  position: relative;
  width: 100%;
}

/* .sign-up .overlap-group-1 {
    background-image: url("/images/driver/mask-group-2.png");
    background-size: 100% 100%;
    width: 100%;
  } */
.sign-up .group-74 {
  background-image: url("/images/driver/mask-group-2.png");
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}

.sign-up .address {
  letter-spacing: 0;
  white-space: nowrap;
}

.sign-up .left-bg {
  background-image: url("/images/driver/mask-group-1.png");
  background-position: left bottom !important;
  /*Positioning*/
  background-repeat: no-repeat;
}

.sign-up .iposita-ondeman-ransparent-bg-1 {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  top: 35%;
  height: 231px;
}

.sign-up .text-7 {
  letter-spacing: 0;
  white-space: nowrap;
}

.sign-up .span1-1 {
  color: var(--bay-of-many);
  font-family: var(--font-family-roboto);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
}

.sign-up .bikes-1 {
  height: 170px;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* top: 10%; */
  /* width: 360px; */
}

.form-check-primary.form-check
  label
  input[type="checkbox"]:checked
  + .input-helper:before,
.form-check-primary.form-check
  label
  input[type="radio"]:checked
  + .input-helper:before {
  background: rgba(31, 66, 140, 1);
}

.form-check {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0;
}

.invalidError {
  background-color: #eeee;
  background-size: 100% 100%;
  border: 1px solid var(--sun) !important;
  outline: 1px solid var(--sun) !important;
}
.maz-input__input {
  /* background-image: url("/images/rectangle-30-1.png");
    background-size: 100% 100%;
      border-radius: 7px; */
  border-color: var(--bay-of-many);
}
.maz-input {
  /* position: relative;
    background-color: none;
    transition: none;
    height: 1rem;
    min-height: 2.5rem; */
  border-color: var(--bay-of-many);
}

.image {
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
}

.overlap-group1 {
  background-color: #1f428c08;
  border-radius: 10px;
  height: 92px;
  left: 0;
  position: relative;
  top: 0x;
  width: 235px;
}

.border-1px-stack {
  border-width: 1px;
  border-style: solid;
  border-color: var(--stack);
}
.auth form .auth-form-btn {
  padding: 1rem 3rem !important;
  line-height: 1 !important;
  font-weight: 400 !important;
  border-radius: 15px !important;
}

.auth form .auth-link {
  font-size: 0.875rem !important;
}

.auth form .auth-link:hover {
  color: initial !important;
}
.btn-primary {
  color: #fff;
  background-color: rgba(31, 66, 140, 1);
  border-color: rgba(31, 66, 140, 1);
  line-height: 1;
  font-weight: 400;
  border-radius: 15px;
}

.btn-primary:hover,
.wizard > .actions a:hover {
  color: #fff;
  background-color: rgba(249, 178, 24, 1) !important;
  border-color: rgba(249, 178, 24, 1) !important;
}

.maz-dialog__header {
  background: rgba(249, 178, 24, 1) !important;
  color: #fff !important;
}

.maz-dialog--fullsize .maz-dialog__header {
  border-radius: 0;
  background: rgba(249, 178, 24, 1) !important;
  color: #fff !important;
}
</style>
